import React from 'react';

// react router dom
import { BrowserRouter} from  'react-router-dom';

import Markup from './markup/Markup';

// css
import 'bootstrap/dist/css/bootstrap.min.css';

// sass
import './assets/scss/style.scss';

// js
import 'bootstrap/dist/js/bootstrap.min.js';

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

const App = () => {
  return (
    <BrowserRouter history={ history }>
        <Markup/>
    </BrowserRouter>
  );
};

export default App;
