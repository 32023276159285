const faq = [
    {
        question: 'Can I try Bentley before I buy?',
        answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    },
    {
        question: 'What is your cancellation policy?',
        answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    },
    {
        question: 'Do I need my own Apple Developer account?',
        answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    },
    {
        question: 'Is there Bentley branding in my app?',
        answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    },
    {
        question: 'Can I preview before I publish?',
        answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    }
];

export default faq;