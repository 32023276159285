const features = [
    {
        icon: 'las la-comments',
        heading: 'Easy to Use',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-headset',
        heading: 'Made for Everyone',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-photo-video',
        heading: 'Share Everything',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-server',
        heading: 'Unlimited Storage',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-user-lock',
        heading: 'Data Protection',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-map-marked-alt',
        heading: 'Use from Anywhere',
        excerpt: 'Heat multiply is second divided fowl there isnt man cattle they wherein lights greater concern open days tree is brought.',
        to: '/feature-detail-1'
    }
];

export default features;